/*
 * @Description:
 * @Author: Rabbiter
 * @Date: 2023-03-04 15:31:03
 */
import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/global.css';
import './assets/font2/iconfont.css'
import axios from "axios";
import VueRouter from 'vue-router';
import './assets/font/iconfont.css'
import router from './router';
import store from './store';
import './store/initialize';
Vue.prototype.$axios=axios;
const currentHost = window.location.hostname+':9131';  // 获取当前访问的主机名和端口
const protocol = window.location.protocol; // 获取当前协议（http 或 https）

// 构建动态的 API 地址，例如 http://0.0.0.0:9131 会根据当前主机的端口自动调整
Vue.prototype.$httpUrl = `${protocol}//${currentHost}`;
Vue.config.productionTip = false
//Vue.use(ElementUI);
Vue.use(VueRouter);
Vue.use(ElementUI,{size:'small'});
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
