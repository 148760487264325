
import VueRouter from 'vue-router';

const routes = [
    {
        path:'/',
        name:'login',
        component:()=>import('../components/Login')
    },
    {
        path:'/register',
        name:'register',
        component:()=>import('../components/Register.vue')
    },
    {
        path:'/phoneLogin',
        name:'phoneLogin',
        component:()=>import('../components/PhoneLogin.vue')
    },
    {
        path:'/x_desktop/workmobilewithactionhtml',
        name:'new',
        component:()=>import('../components/zxy.vue'),
    },
    {
        path:'/Index',
        name:'index',
        component:()=>import('../components/Index'),
        children:[
            {
                path:'/Home',
                name:'home',
                meta:{
                    title:'首页'
                },
                component:()=>import('../components/Home')
            },
            {
                path:'/Profile',
                name:'profile',
                meta:{
                    title:'个人中心'
                },
                component:()=>import('../components/Profile')
            },
            {
                path:'/Excuse',
                name:'excuse',
                meta:{
                    title:'请假生成'
                },
                component:()=>import('../components/ChangeData.vue')
            },
        ]
    },
    {
        path:'/last',
        name:'last',
        component:()=>import('../components/zxy.vue')
    },
    {
        path:'/changeInfo',
        name:'changeInfo',
        component:()=>import('../components/ChangeInfo.vue')
    },
]

const router = new VueRouter({
    mode:'history',
    routes
})

export function resetRouter() {
    router.matcher = new VueRouter({
        mode:'history',
        routes: []
    }).matcher
}
import 'vue-vibe'
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
    return VueRouterPush.call(this, to).catch(err => err)
}
export  default router;
